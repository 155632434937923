<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewInterviewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-interview-sidebar-active', val)"
  >
    <template #default="{ hide }">
       <b-link class="floating-closeright d-flex align-items-center justify-contwnt-center p-25" @click="hide">
        <feather-icon icon="XIcon" size="26" class="h-auto" stroke="#101828" />
      </b-link>
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Recruiters</h5>

        <!-- <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        /> -->
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form autocomplete="off" id="resume-form" class="p-2" @reset.prevent="resetForm">
          <!-- Full Name -->
          <b-form-group label="Recruiters" label-for="recruiters">
            <v-select
              v-model="userData.recruiters"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="recruitersOptions"
              :clearable="true"
              :reduce="(label) => label.code"
              name="role"
              placeholder="Select Recruiters.."
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="validationForm"
              :disabled="is_loading"
            >
              Submit
              <span
                class="spinner-border text-light spinner-border-sm ml-1"
                v-if="is_loading"
              ></span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BButton, VBTooltip } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

import { ref } from "@vue/composition-api";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    vSelect,

    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "isAddNewInterviewSidebarActive",
    event: "update:is-add-new-interview-sidebar-active",
  },
  props: {
    isAddNewInterviewSidebarActive: {
      type: Boolean,
      required: true,
    },
    interviewId: {
      type: String,
      required: false,
      default: "",
    },
    userData: {
      type: Object,
      required: false,
      default() {
        return [];
      },
    },
    recruitersOptions: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      is_loading: false,
    };
  },
  setup() {
    const blankUserData = {
      status: false,
      review: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    validationForm() {
      const self = this;
      if (self.userData.recruiters === "") {
        // self.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Kindly select a recruiter",
        //     icon: "BellIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Kindly select a recruiter`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        return false;
      }
      return new Promise((resolve, reject) => {
        self.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.is_loading = true;
            self.$store
              .dispatch("app-nhr/addRecruiter", {
                nhrId: self.interviewId,
                recruiter: self.userData.recruiters,
              })
              .then((res) => {
                if (res.data.success) {
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Recruiter Added",
                  //     icon: "BellIcon",
                  //     variant: "success",
                  //     text: res.data.message,
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Recruiter Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
                  self.$emit("refetch-data");
                  self.$emit(
                    "update:is-add-new-interview-sidebar-active",
                    false
                  );
                } else {
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Oops! Recruiter Adding Failed",
                  //     icon: "BellIcon",
                  //     variant: "danger",
                  //     text: res.data.message,
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Recruiter Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              })
              .catch((error) => {
                // self.$toast(
                //   {
                //     component: ToastificationContent,
                //     props: {
                //       title: "Oops! Recruiter Adding Failed",
                //       icon: "BellIcon",
                //       variant: "danger",
                //       text: error,
                //     },
                //   });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Recruiter Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              })
              .finally(() => (this.is_loading = false));
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
</style>
