<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewCloseSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-close-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Close New Hire Request /Add Employee
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          id="resume-form3"
          class="p-2"
          @reset.prevent="resetForm"
        >

          <b-form-group
            label=""
            label-for="employees"
          >
            <b-form-checkbox
              v-model="userData.status"
              switch
              inline
            >
              Are you closing this New Hire Request?
            </b-form-checkbox>
          </b-form-group>

          <!-- Full Name -->
          <b-form-group
            label="Employees"
            label-for="employees"
          >
            <v-select
              v-model="userData.employees"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="employeeOptions"
              :clearable="true"
              :reduce="label => label.code"
              multiple
              name="role"
              placeholder="Select Employee.."
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select';

import { ref } from '@vue/composition-api'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('feather-icon', { props: { size: '14', icon: 'XIcon' } }),
  },
  OpenIndicator: {
    render: h => h('feather-icon', { props: { size: '15', icon: 'ChevronDownIcon' }, class: 'open-indicator' }),
  },
})


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    BFormCheckbox,
    vSelect,

    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isAddNewCloseSidebarActive',
    event: 'update:is-add-new-candidate-sidebar-active',
  },
  props: {
    isAddNewCloseSidebarActive: {
      type: Boolean,
      required: true,
    },
    interviewId: {
      type: String,
      required: false,
      default: '',
    },
    userData: {
      type: Object,
      required: false,
      default() {
        return []
      },
    },
    employeeOptions: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup() {
    const blankUserData = {
      status: false,
      review: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    validationForm() {
      const self = this
      if (self.userData.employees.length === 0) {
        // self.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Kindly select a employee',
        //     icon: 'BellIcon',
        //     variant: 'danger',
        //   },
        // })
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Kindly select a employee`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        return false
      }
      return new Promise((resolve, reject) => {
        self.$refs.refFormObserver.validate().then(success => {
          if (success) {
            self.$store.dispatch('app-nhr/closeNHR', {
              nhrId: self.interviewId,
              employees: self.userData.employees,
              status: self.userData.status,
            })
              .then(res => {
                if (res.data.success) {
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: 'New Hire Request Closed',
                  //     icon: 'BellIcon',
                  //     variant: 'success',
                  //     text: res.data.message,
                  //   },
                  // })

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'New Hire Request Closed',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

                  self.$emit('refetch-data')
                  self.$emit('update:is-add-new-close-sidebar-active', false)
                } else {
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: 'Oops! New Hire Request Closing Adding Failed',
                  //     icon: 'BellIcon',
                  //     variant: 'danger',
                  //     text: res.data.message,
                  //   },
                  // })
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! New Hire Request Closing Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              })
              .catch(error => {
                console.log(error)
                // self.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: 'Oops! New Hire Request Closing Adding Failed',
                //     icon: 'BellIcon',
                //     variant: 'danger',
                //     text: error,
                //   },
                // })
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! New Hire Request Closing Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    // Import FilePond styles
    @import '~filepond/dist/filepond.min.css';
    @import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }
    .filepond--credits {
        display: none;
    }
</style>
